import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Head from "../components/Head";
import Box from "@mui/material/Box";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Service from "../components/Service";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import News from "../components/News";
import Menu from "../components/Menu";
import ShopTextSection from "../components/ShopTextSection";
import SecondView from "../components/SecondView";
import Map from "../components/Map";
import HeadOffficeSliderImage from "../components/HeadOfficeSliderImage";
import AOS from "aos";

const HeadOfffice = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        view: file(relativePath: { eq: "secondView/second-view-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        sp_view: file(relativePath: { eq: "secondView/second-view-1-sp.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
  if (typeof document !== `undefined`) {
    console.log("find document");
    AOS.init({ duration: 800, easing: "ease-in-sine", delay: 300 });
  }
  const image = getImage(data.view);
  const spImage = getImage(data.sp_view);
  const imagePathArray = [
    { path: "/head-office/first-view-1.png" },
    { path: "/head-office/first-view-2.png" },
    { path: "/head-office/first-view-3.png" },
    { path: "/head-office/first-view-4.png" },
    { path: "/head-office/first-view-5.png" },
  ];

  return (
    <>
      <Head
        title="フォルカフェ本店 | forucafe"
        description="フォルカフェ本店です。2013年9月、たった9坪の小さな場所からforucafeはスタートしました。一人で読書しながらモーニングに自家製グラノーラ、同僚とカレーランチ、家族にお弁当をテイクアウト、ティータイムに友人とブリュレフレンチトースト...さまざまなシーンでおたのしみいただけます。"
        url="https://forustyle-985bb.web.app/head-office"
        imagePath="https://forustyle-985bb.web.app/ogp-head-office.jpg"
      />
      <Header title="forucafe 本店" />
      <HeadOffficeSliderImage imagePathArray={imagePathArray} />
      <div data-aos="fade-in">
        <ShopTextSection
          bgcolor="#F3F5F5"
          text={`暮らす人の生活にそっと寄り添う 
”いつもの”カフェ`}
        />
      </div>
      <div data-aos="fade-in">
        <SecondView
          data={image}
          spData={spImage}
          text={`2013年9月、たった9坪の小さな場所からforucafeはスタートしました。

一人で読書しながらモーニングに自家製グラノーラ、同僚とカレーランチ、
家族にお弁当をテイクアウト、ティータイムに友人とブリュレフレンチトースト...
さまざまなシーンでおたのしみいただけます。`}
          textTb={`2013年9月、たった9坪の小さな場所から
forucafeはスタートしました。
一人で読書しながらモーニングに自家製グラノーラ、
同僚とカレーランチ、家族にお弁当をテイクアウト、
ティータイムに友人とブリュレフレンチトースト...
さまざまなシーンでおたのしみいただけます。`}
          textSp={`2013年9月、たった9坪の小さな場所から
forucafeはスタートしました。

一人で読書しながらモーニングに自家製グラ
ノーラ、同僚とカレーランチ、
家族にお弁当をテイクアウト、ティータイムに
友人とブリュレフレンチトースト...
さまざまなシーンでおたのしみいただけます。`}
        />
      </div>
      <div data-aos="fade-in">
        <Menu shop="本店" isDetail={true} />
      </div>
      <div data-aos="fade-in">
        <Map
          title={`forucafe本店`}
          textUnderTheTitle={0}
          post={`〒1690051 東京都新宿区西早稲田2-4-26 1F
1F,2-4-26,Nishiwaseda,Shinjuku-ku,Tokyo`}
          nearestStation={`東西線早稲田駅(2番出口)から徒歩5分`}
          phoneNumber={`03-6233-8739`}
          time={`9:00~18:00(17:00L.O.)`}
          isWifi={`無`}
          isPowerSupply={`有`}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12958.867506003793!2d139.7169241!3d35.7085842!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42301c742e4ae9c8!2zZm9ydWNhZmXmnKzlupcgKOODleOCqeODq-OCq-ODleOCpyk!5e0!3m2!1sja!2sjp!4v1645792030912!5m2!1sja!2sjp"
        />
      </div>
      <div data-aos="fade-in">
        <News shop="本店" />
      </div>
      <div data-aos="fade-in">
        <Service />
      </div>
      <div data-aos="fade-in">
        <Recruit />
      </div>
      <div data-aos="fade-in">
        <Footer />
      </div>
    </>
  );
};

export default HeadOfffice;
