import React from "react";
import Slider from "react-slick";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";

const FullImage = ({ children }) => {
  return <Box sx={{ width: "100vw", height: "100vh" }}>{children}</Box>;
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      sx={{
        height: "100vh",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        left: 5,
      }}
    >
      <div
        style={{ position: "relative", zIndex: 10, display: "block" }}
        onClick={onClick}
      >
        <StaticImage
          alt="img"
          src="../images/prevArrow.png"
          placeholder="blurred"
          quality="100"
          style={{ width: "21px" }}
        />
      </div>
    </Box>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      sx={{
        height: "100vh",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: 0,
        right: 5,
      }}
    >
      <div
        style={{ position: "relative", right: 0, zIndex: 10, display: "block" }}
        onClick={onClick}
      >
        <StaticImage
          alt="img"
          src="../images/nextArrow.png"
          placeholder="blurred"
          quality="100"
          style={{ width: "21px" }}
        />
      </div>
    </Box>
  );
};

const HeadOfficeSliderImage = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        first_view_1: file(
          relativePath: { eq: "head-office/first-view-1.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_2: file(
          relativePath: { eq: "head-office/first-view-2.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_3: file(
          relativePath: { eq: "head-office/first-view-3.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_4: file(
          relativePath: { eq: "head-office/first-view-4.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_5: file(
          relativePath: { eq: "head-office/first-view-5.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_1: file(relativePath: { eq: "sp/sphonten1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_2: file(relativePath: { eq: "sp/sphonten2.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_4: file(relativePath: { eq: "sp/sphonten4.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_5: file(relativePath: { eq: "sp/sphonten5.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_6: file(relativePath: { eq: "sp/sphonten6.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_7: file(relativePath: { eq: "sp/sphonten7.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const imageArray = [
    getImage(data.first_view_1),
    getImage(data.first_view_2),
    getImage(data.first_view_3),
    getImage(data.first_view_4),
    getImage(data.first_view_5),
  ];
  const imageSpArray = [
    getImage(data.first_view_sp_1),
    getImage(data.first_view_sp_2),
    getImage(data.first_view_sp_4),
    getImage(data.first_view_sp_5),
    getImage(data.first_view_sp_6),
    getImage(data.first_view_sp_7),
  ];

  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <Box sx={{ display: ["none", "block"] }}>
        <Slider {...settings}>
          {imageArray.map((c) => {
            return (
              <FullImage>
                <GatsbyImage image={c} style={{ height: "100vh" }} />
              </FullImage>
            );
          })}
        </Slider>
      </Box>
      <Box sx={{ display: ["block", "none"] }}>
        <Slider {...settings}>
          {imageSpArray.map((c) => {
            return (
              <FullImage>
                <GatsbyImage image={c} style={{ height: "100vh" }} />
              </FullImage>
            );
          })}
        </Slider>
      </Box>
    </>
  );
};

export default HeadOfficeSliderImage;
