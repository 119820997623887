import React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";

const ShopTextSection = (props) => {
  return (
    <Box
      bgcolor={props.bgcolor}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box padding="100px 0">
        <Box textAlign="center" sx={{ whiteSpace: ["pre", "normal"] }}>
          <WrapBoxTypo
            fs="18px"
            lh={["41px", "49px"]}
            color="#515D59"
            ls={["2px", "5px"]}
          >
            {props.text}
          </WrapBoxTypo>
        </Box>
      </Box>
    </Box>
  );
};

export default ShopTextSection;
